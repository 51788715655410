import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ClientDetails from './pages/ClientDetails';
import CreateClient from './pages/CreateClient';
import Login from './pages/Login';
import Register from './pages/register'; // Компонент Register
import OverdueClients from './pages/OverdueClients';
import ProblematicClients from './pages/ProblematicClients';
import GlobalAnalytics from './pages/GlobalAnalytics';
import ManagerList from './pages/ManagerList'; // Новый маршрут для менеджеров
import { AuthProvider, useAuth } from './utils/auth';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// Компонент для проверки роли пользователя
const RoleBasedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, userRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Публичные маршруты */}
          <Route path="/login" element={<Login />} />
          <Route path="/registersdfuidnofersuereanufreonfru" element={<Register />} />

          {/* Защищенные маршруты */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/client/:id"
            element={
              <PrivateRoute>
                <ClientDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-client"
            element={
              <PrivateRoute>
                <CreateClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/overdue"
            element={
              <PrivateRoute>
                <OverdueClients />
              </PrivateRoute>
            }
          />
          <Route
            path="/problematic"
            element={
              <PrivateRoute>
                <ProblematicClients />
              </PrivateRoute>
            }
          />

          {/* Маршрут для аналитики (доступен только для chief_manager) */}
          <Route
            path="/analytics"
            element={
              <RoleBasedRoute requiredRole="chief_manager">
                <GlobalAnalytics />
              </RoleBasedRoute>
            }
          />

          {/* Маршрут для управления менеджерами (доступен только для chief_manager) */}
          <Route
            path="/managersreerofssifmwfisod"
            element={
              <RoleBasedRoute requiredRole="chief_manager">
                <ManagerList />
              </RoleBasedRoute>
            }
          />

          {/* Маршрут по умолчанию */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
