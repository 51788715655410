import React, { useEffect, useState } from 'react';
import { getAllManagers, deleteManager } from '../utils/api';
import '../styles/ManagerList.css';

const ManagerList = () => {
  const [managersreerofssifmwfisod, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchManagers = async () => {
    try {
      const data = await getAllManagers();
      setManagers(data);
      setLoading(false);
    } catch (err) {
      setError('Ошибка при загрузке менеджеров');
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить менеджера?')) return;

    try {
      await deleteManager(id);
      setManagers((prev) => prev.filter((manager) => manager.id !== id));
    } catch (err) {
      setError('Ошибка при удалении менеджера');
    }
  };

  useEffect(() => {
    fetchManagers();
  }, []);

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="manager-list">
      <h1>Список менеджеров</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Логин</th>
            <th>Роль</th>
            <th>Дата создания</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {managersreerofssifmwfisod.map((manager) => (
            <tr key={manager.id}>
              <td>{manager.id}</td>
              <td>{manager.username}</td>
              <td>{manager.role}</td>
              <td>{new Date(manager.createdAt).toLocaleString()}</td>
              <td>
                <button onClick={() => handleDelete(manager.id)}>Удалить</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerList;
