// frontend/src/pages/register.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../utils/api'; // Функция из API
import '../styles/Register.css';

function RegisterComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('manager');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await register({ username, password, role });
    if (success) {
      navigate('/login'); // Перенаправление на страницу логина при успехе
    } else {
      setError('Ошибка при регистрации'); // Установка сообщения об ошибке
    }
  };

  return (
    <div className="register-container">
      <h2>Регистрация</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Логин</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Пароль</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Роль</label>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="manager">Менеджер</option>
            <option value="chief_manager">Главный менеджер</option>
          </select>
        </div>
        <button type="submit">Зарегистрироваться</button>
      </form>
    </div>
  );
}

export default RegisterComponent;
